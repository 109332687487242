<template>
  <div>
    <ManageForm7 />
  </div>
</template>
<script>
import ManageForm7 from "@/components/ManageForm7/ManageForm7";
export default {
  components: {
    ManageForm7,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>