<template>
  <!-- <v-row align="center" justify="center" class="text-center"> -->
  <v-btn class="mt-0 mr-4"
    ><download-excel
      class="btn btn-default"
      :data="data"
      :fields="jsonFields"
      worksheet="My Worksheet"
      :name="name"
      :header="`${name}` + ' ปี ' + yearSelected"
    >
      <img src="@/assets/Excel-Logo.png" width="50px" />
      exportข้อมูลเอกสาร
    </download-excel></v-btn
  >
  <!-- </v-row> -->
</template>
<script>
import { Decode } from "@/services";
export default {
  // :formDate="dateFrom"
  // :toDate="dateTo"
  props: ["data", "name", "jsonFields"],
  data() {
    return {
      yearSelected: "",
      // json_fields: {
      //   เลขที่หนังสือ: "bookNo",
      //   วันที่ลงส่งหนังสือ: "dateFrom",
      //   ประเภทหนังสือ: "bookType",
      //   ชั้นความเร็ว: "speed",
      //   วันที่ออกหนังสือ: "dateTo",
      //   หน่วยงาน: "agency",
      //   ถึง: "formTo",
      //   เรื่อง: "subject",
      //   ผู้ลงนามสั่งการ: "orderWhoName",
      //   ไฟล์แนบ: "file",
      //   หมายเหตุ: "remark",
      //   การปฏิบัติ: "practice",

      //   // count: 1
      //   // createdAt: (...)
      //   // id: (...)
      //   // updatedAt: (...)
      //   // year: (...)
      // },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    this.yearSelected = JSON.parse(
      Decode.decode(localStorage.getItem("yearSelected"))
    );
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
